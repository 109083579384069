import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, Navbar, Image } from "react-bootstrap";
import "./App.css";
import "./assets/style.css";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import logo from "./assets/thestork.svg";
import config from "./config";
import ReactGA from "react-ga";

// Set app version
import packageJson from "../package.json";
global.appVersion = packageJson.version;

class App extends Component {
  constructor(props) {
    console.log(`App Version: ${global.appVersion}`);
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
    };

    ReactGA.initialize(config.analytics.GOOGLE_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async (event) => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/");
  };

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
    };

    return (
      !this.state.isAuthenticating && (
        <div className="App container">
          <Navbar fluid collapseOnSelect>
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/">
                  <Image src={logo} responsive />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullRight>
                <NavItem key={1} onClick={this.handleLogout}>
                  Enter Code
                </NavItem>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Routes childProps={childProps} />
        </div>
      )
    );
  }
}

export default withRouter(App);
