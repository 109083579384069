import React from "react";

class ToSContent extends React.Component {
  // TODO: Line 107, provide link to privacy policy

  render() {
    return (
      <div className="tos">
        <h5>THE STORK</h5>
        <h6>TERMS AND CONDITIONS</h6>
        <p>
          These Terms and Conditions (<strong>Terms</strong>) set out the terms
          and conditions on which The Stork Limited (<strong>we</strong>,{" "}
          <strong>our </strong>or <strong>us</strong>) agrees to permit you to
          access the content viewing service (the <strong>Service</strong>),
          including through our website,{" "}
          <a href="http://www.thestork.io">www.thestork.io</a>, the Stork
          application and any related websites or applications operated by us,
          (the <strong>App</strong>).
        </p>
        <p>
          By using the Service or the App you agree to be bound by these Terms,
          as altered by us from time to time. If you do not wish to agree to
          these Terms, including any updated version of these Terms, you must
          stop using the Service and the App.
        </p>
        <ol>
          {/* 1 */}
          <li>
            <strong>Our Service</strong>
            <ol>
              <li>
                We provide the Service to allow parents to be, and their family
                and friends, to access ultrasound images through the Internet.
              </li>
              <li>
                Radiology clinics pay a fee for each ultrasound to allow access
                to images from that ultrasound for a fixed period from the date
                the images are taken. During that time, you can download those
                images and/or share those images with friends and family through
                social media channels.
              </li>
              <li>
                You can use the unique code provided by your radiology clinic to
                access images relating to your ultrasound.
              </li>
              <li>
                You agree with us sharing images from your pregnancy with any
                person who accesses the Service using your unique code.
              </li>
            </ol>
          </li>
          {/* 2 */}
          <li>
            <strong>Eligibility</strong>
            <ol>
              <li>
                The Service is only available to, and may only be used by, New
                Zealand residents who can form legally binding contracts under
                New Zealand law. The Service is not available to persons outside
                New Zealand or persons who are under the age of 18.
              </li>
              <li>
                We may decline to allow you to access the Service for any reason
                and we are not obliged to state such reason.
              </li>
            </ol>
          </li>
          {/* 3 */}
          <li>
            <strong>Access to the Service</strong>
            <ol>
              <li>
                When you consent to use of the Service through your radiology
                clinic, you will have access for a fixed period specified by
                your radiology clinic. Once that period has expired, you will no
                longer be able to access the Service.
              </li>
              <li>
                You are responsible for downloading copies of your images before
                the expiry of the period. We do not promise to keep copies of
                your images once your fixed period of access has ended.
              </li>
            </ol>
          </li>
          {/* 4 */}
          <li>
            <strong>Intellectual Property Rights</strong>
            <ol>
              <li>
                You acknowledge and agree that all intellectual property rights
                in the Service and the App are owned by us or our licensors. You
                may not in any form or by any means adapt, reproduce, store,
                perform, publish or create any derivative works from any part of
                the App or commercialise or on-sell any information obtained
                from the App or from us.
              </li>
              <li>
                You agree to abide by these Terms and such other terms and
                conditions relating to use of the App and any materials on the
                App as we or our licensors may specify from time to time.
              </li>
            </ol>
          </li>
          {/* 5 */}
          <li>
            <strong>Errors and Complaints</strong>
            <ol>
              <li>
                You must contact us immediately if there is an error or omission
                in any of the information listed within the Service or if you
                think that you have access to images that do not relate to you
                or the person who provided you with access to the Service.
              </li>
              <li>
                We will use all reasonable endeavours to correct any such error
                as quickly as possible, but will not be liable for any losses
                (whether direct, indirect, or consequential) arising from an
                error or omission in the information listed within the Service
                or any issues with access to images within the App.
              </li>
            </ol>
          </li>
          {/* 6 */}
          <li>
            <strong>Entitlement to access to the Service</strong>
            <p>
              You agree that access to the Service is being provided to you by
              the radiology clinic that you use, not by us. Because of that, you
              agree that:
            </p>
            <ul>
              <li>
                we are not liable to you for any failure to provide the Service
                to you;
              </li>
              <li>
                your entitlement to receive the Service is determined by the
                radiology clinic; and
              </li>
              <li>
                we hold your images for the benefit of the radiology clinic, and
                we are entitled to hold them on any servers, and remove or
                delete them, as we see fit.
              </li>
            </ul>
          </li>
          {/* 7 */}
          <li>
            <strong>Use of the Service</strong>
            <p>
              You agree that, when you use the Service or access any images,
              either through the App or in any other way:
            </p>
            <ul>
              <li>
                you will not knowingly access any images that do not relate to
                your or the person who provided you with access to the Service;
              </li>
              <li>
                you are responsible for the actions of any person that accesses
                the Service using your unique code, whether or not you
                intentionally gave them that code; and
              </li>
              <li>
                you are responsible for keeping your unique code secure and
                confidential at all times.
              </li>
            </ul>
          </li>
          {/* 8 */}
          <li>
            <strong>At your risk</strong>
            <ol>
              <li>
                Your use of the Service and the images you access through the
                Service are conducted entirely at your own risk.
              </li>
              <li>
                You agree that you will not rely on images accessed through the
                Service for obtaining medical advice, but rather ask the
                radiology clinic to provide your images directly to your medical
                advisor.
              </li>
              <li>
                You agree that we take no responsibility or liability for any
                misconduct of any of members of the Service, without limitation,
                members that have registered under false pretences or who
                attempt to access images that do not relate to them.
              </li>
              <li>
                We give no undertakings, representations or warranties in
                relation to any images made available claims through the
                Service.
              </li>
              <li>
                The App and the Service are provided on an &ldquo;as is&rdquo;
                and &ldquo;as available&rdquo; basis. To the maximum extent
                provided by law, we disclaim and exclude any implied conditions
                or warranties (including, without limitation, any warranties of
                merchantability and fitness for a particular purpose).
              </li>
              <li>
                Without limiting clause 8.5, we do not warrant that the Service
                or the App will be uninterrupted, timely, secure, error-free or
                that any information provided through the Service on the App is
                error-free or reliable.
              </li>
            </ol>
          </li>
          {/* 9 */}
          <li>
            <strong>Details</strong>
            <ol>
              <li>
                You agree that you are responsible for keeping the information
                connected with your account up-to-date.
              </li>
              <li>
                You agree that, when you access the Service, and whenever you
                provide us with any information, either through the App or in
                any other way:
                <ul>
                  <li>
                    all information that you provide to use will be accurate,
                    complete and up-to-date, and you will provide us with all
                    information requested; and
                  </li>
                  <li>
                    for any personal information you provide to us about any
                    person, you have obtained that person&rsquo;s consent to
                    provide us with that information and for us to use that
                    information in accordance with these Terms and to provide
                    the Service.
                  </li>
                </ul>
              </li>
              <li>
                You agree not to:
                <ul>
                  <li>impersonate another person;</li>
                  <li>
                    use a false name or a name you are not authorised to use;
                  </li>
                  <li>
                    create a false identity or provide a false address or email
                    address;
                  </li>
                  <li>
                    be misleading as to the identity or origin of any
                    communications; or
                  </li>
                  <li>use the App or the Service for any illegal purpose.</li>
                </ul>
              </li>
            </ol>
          </li>
          {/* 10 */}
          <li>
            <strong>Compliance with policies</strong>
            <p>
              By using the App, you agree to comply with any policies or other
              requirements issued by us from time to time, and those policies
              and other requirements will form part of these Terms.
            </p>
          </li>
          {/* 11 */}
          <li>
            <strong>Privacy policy</strong>
            <p>
              Your privacy is important to us. We may gather, use and disclose
              information about you in accordance with our privacy policy, which
              may be found{" "}
              <a
                href="https://www.thestork.nz/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              . Our privacy policy forms part of these Terms, and by using the
              Service or the App you agree to our privacy policy.
            </p>
          </li>
          {/* 12 */}
          <li>
            <strong>Security</strong>
            <ol>
              <li>
                We will use all reasonable endeavours to effect and maintain
                adequate security measures to safeguard your images and the
                information you provide to us from access or use by unauthorised
                persons, but we provide no warranty or guarantee as to security.
              </li>
              <li>
                You are responsible for keeping your access information,
                including your key and unique code, secret and secure. You agree
                not to permit any other person to use your name or access your
                account and not to disclose (directly or indirectly) any
                information that may allow another person to gain access to your
                account.
              </li>
            </ol>
          </li>
          {/* 13 */}
          <li>
            <strong>Your profile</strong>
            <p>
              We reserve the right to suspend, terminate, cancel or close your
              account with the Service if:
            </p>
            <ul>
              <li>
                we suspect that you have breached these Terms or breached any
                applicable law;
              </li>
              <li>
                we otherwise consider that such suspension or termination is
                required; or
              </li>
              <li>
                at any time by giving you not less than 30 days&rsquo; notice
                through to the last email address you provided to us or through
                the App.
              </li>
            </ul>
          </li>
          {/* 14 */}
          <li>
            <strong>App integrity</strong>
            <p>
              You must not attempt to damage, interfere with or harm the App or
              the Service, or any network or system underlying or connected to
              them in any way whatsoever, or to access any part of, or content
              within, the App or Service that you are not entitled to access.
            </p>
          </li>
          {/* 15 */}
          <li>
            <strong>Amendments</strong>
            <ol>
              <li>
                We may amend these Terms from time to time. Notice of any such
                amendments will be announced on this App and will be effective
                immediately, unless we state otherwise. You are responsible for
                reviewing these Terms regularly to obtain timely notice of any
                such changes.
              </li>
              <li>
                Your continued use of the Service or our App will constitute
                your acceptance of any changes or revisions to these Terms.
              </li>
            </ol>
          </li>
          {/* 16 */}
          <li>
            <strong>Maximum Liability</strong>
            <p>
              If we are found to have any liability under or in connection with
              these Terms, to the extent permitted by law, the maximum amount of
              our liability arising out of all claims under these Terms or
              relating to the Service or the App will not in any circumstances
              exceed NZ$100.
            </p>
          </li>
          {/* 17 */}
          <li>
            <strong>Indemnity</strong>
            <p>
              You agree to at all times indemnify us and our officers, servants
              and agents against any and all liability, claims, losses, damages,
              costs or other expenses of any nature whatsoever awarded against,
              incurred or suffered by us or our officers, servants and agents
              arising out of or in connection with your use of the Service or
              the Service by any person using your unique code, any information
              provided by you and/or any breach of these Terms.
            </p>
          </li>
          {/* 18 */}
          <li>
            <strong>Waiver</strong>
            <p>
              Our failure or delay to exercise any right or remedy we may have
              under these Terms will not be construed or operate as a waiver
              thereof, nor will any single or partial exercise of any right or
              remedy preclude the further exercise of such right or remedy.
            </p>
          </li>
          {/* 19 */}
          <li>
            <strong>Governing Law</strong>
            <p>
              These Terms will be governed by the law of New Zealand. You agree
              to submit to the non-exclusive jurisdiction of the courts of New
              Zealand.
            </p>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    );
  }
}

export default ToSContent;
