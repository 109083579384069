import React, { Component } from "react";
import { PageHeader } from "react-bootstrap";
import { Auth, API } from "aws-amplify";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
// import { Player, BigPlayButton } from 'video-react';
import config from "../config";
import "video-react/dist/video-react.css"; // import css for video-react
import Gallery from "react-grid-gallery";
import Moment from "moment";
import { Glyphicon } from "react-bootstrap";
// import { Button, Glyphicon } from "react-bootstrap";
// import FontAwesome from 'react-fontawesome';
// import faStyles from '../../node_modules/font-awesome/css/font-awesome.css'
import ReactModal from "react-modal";
// import FlickityComponent from "react-flickity-component";
import FlickityComponent from "@jamesbliss/react-flickity";
import "flickity/css/flickity.css";
import "./Gallery.css"; // This must be imported _after_ video-react.css
import MediaLoader from "../components/MediaLoader";
import ReactGA from "react-ga";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      files: [],
      filePath: "",
      fileIndex: 0,
      mediaFiles: [],
      studyDate: props.studyDate,
      showModal: false,
      // imageSelect: false,
      shareFiles: [],
      showImageSelectModal: false,
      className: "image-select-modal-modal",
      overlayClassName: "react-modal-overlay",
      postMessage:
        "Meet our newest addition to the family ❤️\n\nThanks #AscotRadiology and www.thestork.nz for an amazing ultrasound scan 🙂",
    };
    this.thumbnailClick = this.thumbnailClick.bind(this);
    this.selectThumbClick = this.selectThumbClick.bind(this);
    // this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCellSelect = this.handleCellSelect.bind(this);
    this.handleMediaLoaded = this.handleMediaLoaded.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.loadMediaFiles = this.loadMediaFiles.bind(this);
    ReactGA.initialize(config.analytics.GOOGLE_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  // handleOpenModal() {
  //   ""
  //   this.setState({ showModal: true });
  // }

  handleCloseModal() {
    this.setState({
      showModal: false,
      showImageSelectModal: false,
      // imageSelect: false,
    });
    ReactGA.event({
      category: "User",
      action: "Closed Image Viewer",
    });
  }

  handleCellSelect(cell) {
    // this.setState({ showModal: false });
  }

  // flickitySelect2() {
  //    if (flickityInstance) {
  //       flickityInstance.select(1);
  //    }
  // }

  // Fix for Modal.setAppElement(el) issue (https://github.com/reactjs/react-modal/issues/133)
  UNSAFE_componentWillMount() {
    ReactModal.setAppElement("body");
  }

  async componentDidMount() {
    const cookies = new Cookies();

    // if (!this.props.isAuthenticated) {
    //   return;
    // }
    try {
      // If no props.location.state then there is state inconsistencies,
      // log them out.
      if (!this.props.location.state) {
        this.handleLogout();
      }
      const results = await this.getFiles();

      cookies.set(
        "CloudFront-Key-Pair-Id",
        results.cookies["CloudFront-Key-Pair-Id"],
        { path: "/", domain: config.domain.CLOUDFRONT }
      );
      cookies.set("CloudFront-Policy", results.cookies["CloudFront-Policy"], {
        path: "/",
        domain: config.domain.CLOUDFRONT,
      });
      cookies.set(
        "CloudFront-Signature",
        results.cookies["CloudFront-Signature"],
        { path: "/", domain: config.domain.CLOUDFRONT }
      );
      this.setState({ files: results.files });
      this.setState({ filePath: results.path });
      this.loadMediaFiles();
      this.loadShareFiles();
    } catch (e) {
      alert(e);
      this.handleLogout();
    }
    this.setState({ isLoading: false });
  }

  userHasAuthenticated = (authenticated) => {
    this.props.isAuthenticated = authenticated;
  };

  handleLogout = async (event) => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/");
    ReactGA.event({
      category: "User",
      action: "Logged out",
    });
  };

  selectFile(index) {
    // TODO: Save order that user selects photos.

    var shareFiles = this.state.shareFiles.slice();
    var img = shareFiles[index];

    if (img.hasOwnProperty("isSelected")) {
      img.isSelected = !img.isSelected;
    } else {
      img.isSelected = true;
    }
    this.setState({
      shareFiles: shareFiles,
    });
  }

  thumbnailClick(index, event) {
    // Show image viewing modal
    if (event) {
      event.preventDefault();
    }
    ReactGA.event({
      category: "User",
      action: "Opened Image Viewer",
    });
    this.setState({
      fileIndex: index,
      showModal: true,
    });
    ReactGA.modalview("image-viewer");
    return;
  }

  selectThumbClick(index, event) {
    // Toggle image selected
    if (event) {
      event.preventDefault();
    }
    // if (this.state.imageSelect) {
    this.selectFile(index);
    // }
  }

  handleMediaLoaded() {
    // Force Flickity to resize when image/video loaded
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
  }

  getFiles() {
    try {
      return API.get("stork", `/files/${this.props.match.params.id}`);
    } catch (error) {
      console.log(error);
    }
  }

  loadMediaFiles() {
    var mediaFiles = this.state.mediaFiles.slice();
    var files = this.state.files.slice();
    var path = this.state.filePath;
    var thumbnail;

    files.forEach((file) => {
      if (file.metadata["object-type"] === "image") {
        thumbnail = `${path}/thumb-${file.name}`;
      } else {
        // video file
        var fileBase = `${file.name}`.split(".mp4").join("");
        thumbnail = `${path}/thumb-${fileBase}.jpg`;
      }
      mediaFiles.push({
        src: `${path}/${file.name}`,
        thumbnail: thumbnail,
        type: file.metadata["object-type"],
        // 'thumbnailWidth': 320 + Math.floor((Math.random() * 100) + 1),
        thumbnailWidth: 320,
        thumbnailHeight: 212,
      });
    });
    this.setState({
      mediaFiles: mediaFiles,
    });
  }

  loadShareFiles() {
    var shareFiles = this.state.shareFiles.slice();
    var files = this.state.files.slice();
    files.forEach((file) => {
      // var fileExt = `${file.url}`.split(".jpg");
      // var thumb = fileExt.join("-thumb.jpg");
      shareFiles.push({
        src: `${file.img}`,
        thumbnail: `${file.thumb}`,
        // 'thumbnailWidth': 320 + Math.floor((Math.random() * 100) + 1),
        thumbnailWidth: 120,
        thumbnailHeight: 100,
      });
    });
    this.setState({
      shareFiles: shareFiles,
    });
  }

  renderFilesList(files) {
    const flickityArray = files.map((file) => (
      <div className="carousel-cell" key={file.src}>
        <MediaLoader
          src={file.src}
          type={file.type}
          onLoad={this.handleMediaLoaded}
          thumb={file.thumbnail}
        >
          {/* this first child does nothing right now */}
          {file.type === "image" ? (
            <img alt="" />
          ) : (
            <video preload="auto" width="100%" controls poster={file.thumbnail}>
              <source src={file.src} type="video/mp4" />
            </video>
          )}
          <Glyphicon glyph="ban-circle" />
          <Glyphicon glyph="refresh" className="spinning" />
        </MediaLoader>
      </div>
    ));
    // const flickityArray = files.map(file => (
    //   <div className="carousel-cell" key={file.key}>
    //     <ImageLoader
    //       src={file.img}
    //     >
    //       {file.metadata["object-type"] === "image"
    //         ? <img alt="" onLoad={this.handleMediaLoaded} />
    //         : <video preload="auto" width="100%" controls poster={file.thumb} onLoad={this.handleMediaLoaded}>
    //             <source src={file.img} type="video/mp4" />
    //           </video>
    //       }
    //       <Glyphicon glyph="ban-circle" />
    //       <Glyphicon glyph="refresh" className="spinning" />
    //     </ImageLoader>
    //   </div>
    // ));

    const flickityOptions = {
      imagesLoaded: true,
      wrapAround: true,
      pageDots: false,
      adaptiveHeight: true,
      initialIndex: this.state.fileIndex,
    };
    // this.state.isOpen = true;

    return (
      <div>
        <Gallery
          images={this.state.mediaFiles}
          backdropClosesModal={true}
          onClickThumbnail={this.thumbnailClick}
          onSelectImage={this.selectFile}
          enableLightbox={false}
          enableImageSelection={false}
        />
        <ReactModal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          contentLabel="Lightbox Modal"
          className="react-modal-modal"
          overlayClassName="react-modal-overlay"
        >
          <FlickityComponent
            className={"carousel"} // default ''
            elementType={"div"} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            onSelect={this.handleCellSelect}
            // flickityRef={flickityRef()}
          >
            {flickityArray}
          </FlickityComponent>
        </ReactModal>
      </div>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>The Stork</h1>
        <p>See what the stork is bringing</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  renderFiles() {
    return (
      <div className="scans">
        <PageHeader>
          {this.state.isLoading ? (
            <Glyphicon glyph="refresh" className="spinning" />
          ) : (
            Moment(this.props.location.state.studyDate, " YYYY-MM-DD").format(
              "dddd, Do MMMM YYYY"
            )
          )}
        </PageHeader>

        <div className="gallery">
          {/* <ListGroup> */}
          {!this.state.isLoading && this.renderFilesList(this.state.mediaFiles)}
          {/* </ListGroup> */}
        </div>
        <div className="footer">{!this.state.isLoading}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="Gallery">
        {this.props.isAuthenticated ? this.renderFiles() : this.renderLander()}
      </div>
    );
  }
}
