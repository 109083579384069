const hostname = window && window.location && window.location.hostname;
let config;
console.log(hostname);

if (hostname === "stork.pics") {
  // Production
  config = {
    REGION: "ap-southeast-2",
    cognito: {
      REGION: "ap-southeast-2",
      USER_POOL_ID: "ap-southeast-2_ygYTG2XUa",
      IDENTITY_POOL_ID: "ap-southeast-2:388b3c86-4e99-4754-8296-afd078348b2e",
      APP_CLIENT_ID: "7cfkegldbbr4ftemma6gd7p5ne",
    },
    apiGateway: {
      REGION: "ap-southeast-2",
      // URL: `https://w1zoe34r50.execute-api.${this.apiGateway.REGION}.amazonaws.com/prod`
      URL: "https://api.thestork.nz/v1",
    },
    s3: {
      BUCKET: "temp.thestork.nz",
    },
    social: {},
    analytics: {
      GOOGLE_ID: "UA-123758486-2",
    },
    domain: {
      BASE: "stork.pics",
      CLOUDFRONT: ".stork.pics",
    },
  };
} else {
  // Development
  config = {
    REGION: "ap-southeast-2",
    cognito: {
      REGION: "ap-southeast-2",
      USER_POOL_ID: "ap-southeast-2_RUmByIsZM",
      IDENTITY_POOL_ID: "ap-southeast-2:f966cb7a-3f11-43e3-8904-a5549f07bfbe",
      APP_CLIENT_ID: "1d8rooot6p9e85lphi159bnh9t",
    },
    apiGateway: {
      REGION: "ap-southeast-2",
      // URL: `https://w1zoe34r50.execute-api.${this.apiGateway.REGION}.amazonaws.com/prod`
      URL: "https://api.stork.red/v1",
    },
    s3: {
      BUCKET: "media.stork.red",
    },
    social: {},
    analytics: {
      GOOGLE_ID: "",
    },
    domain: {
      BASE: "stork.red",
      CLOUDFRONT: ".stork.red",
    },
  };
}

export default config;
