import React from "react";
import PropTypes from "prop-types";

const Status = {
  PENDING: "pending",
  LOADING: "loading",
  LOADED: "loaded",
  FAILED: "failed",
};

export default class MediaLoader extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    type: PropTypes.string,
    srcSet: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    children: PropTypes.arrayOf(PropTypes.node),
    // Allow any extras
  };

  constructor(props) {
    super(props);
    this.state = {
      status: props.src ? Status.LOADING : Status.PENDING,
    };
    if (React.Children.count(props.children) !== 3)
      console.error("wrong # of children provided to MediaLoader");
    this.handleLoad = this.handleLoad.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleLoad(event) {
    this.setState({ status: Status.LOADED });
    if (this.props.onLoad) this.props.onLoad(event);
  }

  handleError(error) {
    this.setState({ status: Status.FAILED });
    if (this.props.onError) this.props.onError(error);
  }

  getClassName() {
    let className = `medialoader medialoader-${this.state.status}`;
    if (this.props.className)
      className = `${className} ${this.props.className}`;
    return className;
  }

  render() {
    const { wrapperProps, children } = this.props;
    const childrenArray = React.Children.toArray(children);

    return (
      <div {...wrapperProps} className={this.getClassName()}>
        <div className="content">
          {this.props.type === "image" ? (
            <img
              src={this.props.src}
              alt=""
              onLoad={this.handleLoad}
              onError={this.handleError}
            />
          ) : (
            <video
              preload="auto"
              width="100%"
              controls
              poster={this.props.thumb}
              onLoad={this.handleLoad}
              onLoadedData={this.handleLoad}
              onProgress={this.handleLoad}
              onCanPlay={this.handleLoad}
              onError={this.handleError}
            >
              <source src={this.props.src} type="video/mp4" />
            </video>
          )}
        </div>
        {this.state.status === Status.FAILED && childrenArray[1]}

        {(this.state.status === Status.LOADING ||
          this.state.status === Status.PENDING) &&
          childrenArray[2]}
      </div>
    );
  }
}
