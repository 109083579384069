import { Auth } from "aws-amplify";
import React, { Component } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  Glyphicon,
  HelpBlock,
  // ControlLabel,
  PageHeader,
} from "react-bootstrap";
import ReactModal from "react-modal";
import "../assets/style.css";
import LoaderButton from "../components/LoaderButton";
import "../components/TermsOfService.css";
import ToSContent from "../components/ToSContent";
import config from "../config";
import "./Validate.css";

export default class Validate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      key: props.match.params.key || "",
      code: "",
      showModal: false,
      className: "tos-modal-modal",
      overlayClassName: "tos-modal-overlay",
      agreeToS: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  // Fix for Modal.setAppElement(el) issue (https://github.com/reactjs/react-modal/issues/133)
  UNSAFE_componentWillMount() {
    ReactModal.setAppElement("body");
  }

  handleOpenModal(e) {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  validateForm() {
    return this.state.code.length > 0 && this.state.key.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      var result = await Auth.signIn(
        this.state.key.toLowerCase().trim(),
        this.state.code.toUpperCase().trim()
      );
      // console.log({result});
      this.props.userHasAuthenticated(true);

      // Show ToS modal
      // this.setState({ showModal: true });

      // Read user properties from JWT token
      var user = result.signInUserSession.idToken.payload;

      // if (typeof user['custom:revoked'] === 'undefined') {
      //   user['custom:revoked'] = 0
      // };
      this.setState({ isLoading: false });
      if (user["custom:revoked"] === "0" || !("custom:revoked" in user)) {
        this.props.history.push({
          pathname: `/${this.state.key.toLowerCase()}/${user.sub}`,
          state: {
            studyDate: user["custom:study_date"],
          },
        });
      } else if (user["custom:revoked"] === "expired") {
        alert("\nCODE EXPIRED\n\nPlease contact your radiology clinic to request a new code.");
      } else {
        alert("\nCODE REVOKED\n\nThis code has been revoked. Please contact your radiology clinic for more information.");
      }
    } catch (e) {
      this.setState({ isLoading: false });
      // console.log(e)
      alert("Invalid code or key");
      // TODO: Nicer alert box
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
    // this.props.requestUser(this.props.match.params.userId);
    // console.log(this.state.showModal);
  };

  handleToggle(e) {
    this.setState({ agreeToS: e.target.checked });
  }

  render() {
    return (
      <div className="Validate">
        <ReactModal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          contentLabel="The Stork Terms and Conditions"
          className={`${this.state.className}`}
          overlayClassName={`${this.state.overlayClassName}`}
        >
          <Button
            className="modal-close-button"
            onClick={this.handleCloseModal}
          >
            <Glyphicon glyph="remove-sign" />
          </Button>
          <ToSContent />
        </ReactModal>

        <PageHeader>Enter Your Code</PageHeader>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="key" bsSize="large">
            {/* <ControlLabel>key</ControlLabel> */}
            <FormControl
              className="key"
              autoFocus={!this.state.key}
              type="key"
              placeholder="key"
              value={this.state.key}
              onChange={this.handleChange}
            />
            <HelpBlock>
              {`https://${config.domain.BASE}`}/
              <b>
                <i>key</i>
              </b>
            </HelpBlock>
          </FormGroup>
          <FormGroup controlId="code" bsSize="large">
            {/* <ControlLabel>code</ControlLabel> */}
            <FormControl
              className="code"
              autoFocus={this.state.code}
              type="code"
              placeholder="Code"
              value={this.state.code}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              inline={true}
              title="I agree to The Stork's Terms of Service"
              onChange={this.handleToggle}
              checked={this.state.agreeToS}
            >
              {/* eslint-disable-next-line */}I agree to The Stork's{" "}
              <a href="" onClick={this.handleOpenModal}>
                &nbsp;Terms of Service
              </a>
            </Checkbox>
          </FormGroup>

          <LoaderButton
            block
            bsSize="large"
            disabled={
              !this.validateForm() ||
              !this.state.agreeToS ||
              this.state.isLoading
            }
            type="submit"
            isLoading={this.state.isLoading}
            text="Verify"
            loadingText="Verifying..."
          />
        </form>
      </div>
    );
  }
}
