import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./containers/NotFound";
import Validate from "./containers/Validate";
import Gallery from "./containers/Gallery";

// Routing
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
// import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Validate} props={childProps} />
    <AppliedRoute path="/:key" exact component={Validate} props={childProps} />
    <AuthenticatedRoute
      path="/:key/:id"
      exact
      component={Gallery}
      props={childProps}
    />
    {/* Catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
